<template lang="pug">
  .candidate-exam-info
    span.text-label Journey:&nbsp;
    template(v-if="exam.journey")
      span {{ exam.journey.internal_name }}
      div(v-for="(journey, index) in exam.journey.journey_days" key="index")
        span.exam-item {{ index + 1 }}. {{ cityName(journey.course_day.city) }} ({{ journey.course_day.date | DDMMYYYYFormatting }}) {{ journey.course_day.name || '' }}
    template(v-else)
      span -
</template>

<script>
import { DDMMYYYYFormatting } from "@/util"

import { mapGetters } from "vuex";

export default {
  name: 'CandidateExamInfo',

  props: {
    exam: {type: Object, required: true}
  },

  filters: {
    DDMMYYYYFormatting
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    })
  },

  methods: {
    cityName(cityID) {
      let city = this.cities.find(city => city.ID === cityID)
      return city.name
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-exam-info {
  .exam-item {
    font-size: 12px;
  }
}
</style>
